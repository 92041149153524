import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { dividerStyles } from './divider.css.js';
export const Divider = ({ orientation = 'horizontal', spacing = 'none', 
/**
 * Sets a fixed height or width depending on orientation.
 */
fixedSize, className, }) => {
    const dividerInlineStyle = {
        horizontal: {
            width: fixedSize,
        },
        vertical: {
            height: fixedSize,
        },
    };
    return (_jsx("div", { className: clsx([
            dividerStyles({
                orientation,
                spacing,
                size: fixedSize ? 'fixed' : 'fill',
            }),
            className,
        ]), style: fixedSize ? dividerInlineStyle[orientation] : {} }));
};
