import { jsx as _jsx } from "react/jsx-runtime";
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { OverlayScrollbars, ClickScrollPlugin } from 'overlayscrollbars';
// eslint-disable-next-line import/no-unresolved
import 'overlayscrollbars/overlayscrollbars.css';
import { scrollbarTheme } from './scrollbar.css.js';
// initialize the click to scroll plugin
OverlayScrollbars.plugin([ClickScrollPlugin]);
export const Scrollbar = ({ children }) => {
    return (_jsx(OverlayScrollbarsComponent, { defer: true, element: "span", options: {
            scrollbars: {
                autoHide: 'move',
                autoHideDelay: 500,
                clickScroll: true,
                dragScroll: true,
                theme: `os-theme-dark ${scrollbarTheme}`,
            },
        }, children: children }));
};
