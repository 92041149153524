import { ReactNode, useState } from 'react';
import { Banner, ButtonPrimary, Spinner } from '@knapsack/toby';
import { useCheckIfFigmaTokenExists } from '@/services/figma-oauth-client';
import { trackEvent } from '@/utils/analytics';
import type { TrackEventLocation } from '@/utils/track-events';
import { useKsLocation } from '@/utils/ks-location.hooks';
import { oAuthLogin } from '../users/utils/oauth-utils';

export const handleFigmaLogin = async ({
  onError,
  onSuccess,
  locationType,
}: {
  onError: (errorMsg: string) => void;
  onSuccess: () => void;
  locationType: TrackEventLocation;
}) => {
  const service = 'figma';
  try {
    trackEvent({
      type: 'OAuth Request Started',
      metadata: { locationType, service },
    });
    await oAuthLogin({ service });
    onSuccess();
    trackEvent({
      type: 'OAuth Request Complete',
      metadata: { locationType, service },
    });
  } catch (error: any) {
    const errorMessage = error.message;
    trackEvent({
      type: 'OAuth Request Error',
      metadata: {
        locationType,
        service,
        error: errorMessage,
      },
    });
    onError(errorMessage);
  }
};

export const DesignSrcAuthChecker = ({ children }: { children: ReactNode }) => {
  const { isLoading, data, error, isSuccess, refetch } =
    useCheckIfFigmaTokenExists();
  const { type: locationType } = useKsLocation();
  const [loggingIn, setLoggingIn] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  if (isLoading) return <Spinner />;
  const hasFigmaToken = isSuccess && data?.hasToken;

  if (error && error.message.includes('Figma token is invalid or expired')) {
    return (
      <Banner type="error" title={error.message}>
        <div>
          <ButtonPrimary
            label={loggingIn ? 'Logging in...' : 'Reauthorize Figma'}
            disabled={loggingIn}
            loading={loggingIn}
            onTrigger={async () => {
              setLoggingIn(true);
              await handleFigmaLogin({
                locationType,
                onError: setErrorMsg,
                onSuccess: refetch,
              });
              setLoggingIn(false);
            }}
          />
        </div>
      </Banner>
    );
  }

  if (errorMsg) {
    return <Banner type="error" title={errorMsg} />;
  }

  if (!hasFigmaToken) {
    return (
      <Banner type="warning" title="You are not logged into your Figma account">
        <div>
          <ButtonPrimary
            label={loggingIn ? 'Logging in...' : 'Login to Figma'}
            disabled={loggingIn}
            loading={loggingIn}
            onTrigger={async () => {
              setLoggingIn(true);
              await handleFigmaLogin({
                locationType,
                onError: setErrorMsg,
                onSuccess: refetch,
              });
              setLoggingIn(false);
            }}
          />
        </div>
      </Banner>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
