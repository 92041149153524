import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useId } from 'react';
import { isEmpty, slugify } from '@knapsack/utils';
import { NestedAction, } from '../inputs/nested-action/index.js';
import { TextLabel } from '../text/index.js';
import { formField, formFieldInput, formFieldLabel, formFieldError, requiredStar, formFieldAction, } from './form-field.css.js';
import { IconButtonTertiary } from '../button/index.js';
export const FormField = ({ children, disabled = false, error, fieldAction, helperDisplay = 'caption', helperText, id, inputWidth = 'fill', label, labelPosition = 'top', labelWidth, required, condensed = false, }) => {
    if (helperText &&
        typeof helperText !== 'string' &&
        helperDisplay === 'tooltip') {
        throw new Error('helperText must be a string when helperDisplay is set to tooltip');
    }
    const hasCaption = helperText && helperDisplay === 'caption';
    return (_jsxs("div", { className: formField({
            condensed,
            labelPosition,
            hasError: error && error.length > 0,
        }), "data-testid": `form-field--${slugify({ string: label })}`, children: [
            // dont render a lable if it's an empty string
            !isEmpty(label) && (_jsxs("label", { className: formFieldLabel({
                    direction: helperDisplay === 'tooltip' ? 'horizontal' : 'vertical',
                    disabled,
                    alignCenter: labelPosition !== 'top' && !hasCaption,
                    labelPosition,
                    width: labelWidth,
                }), htmlFor: id, children: [_jsxs(TextLabel, { weight: "medium", children: [label, required && _jsx("span", { className: requiredStar, children: "*" })] }), helperText && helperDisplay === 'tooltip' && (_jsx(IconButtonTertiary, { icon: "info-circle", size: "xsmall", color: "subtle", label: typeof helperText === 'string' ? helperText : '', tooltipPlacement: "top", onTrigger: () => { } })), hasCaption && (_jsx(TextLabel, { display: "block", size: "small", color: !disabled ? 'subtle' : undefined, spacing: "xsmall", children: helperText }))] })), fieldAction && (_jsx("span", { className: formFieldAction, children: _jsx(NestedAction, { ...fieldAction }) })), _jsxs("div", { className: formFieldInput({
                    inline: labelPosition !== 'top',
                    width: inputWidth,
                }), children: [children, error && (_jsx("div", { className: formFieldError, children: _jsx(TextLabel, { size: "small", color: "error", children: error }) }))] })] }));
};
export const withFormField = (Component) => {
    const FormFieldWrapper = forwardRef((props, ref) => {
        const { label, disabled, error, helperDisplay, helperText, inputWidth, labelPosition, labelWidth, required, } = props;
        const id = useId();
        return (_jsx(FormField, { disabled: disabled, label: label, error: error, helperDisplay: helperDisplay, helperText: helperText, inputWidth: inputWidth, labelPosition: labelPosition, labelWidth: labelWidth, required: required, id: id, children: _jsx(Component, { ...props, id: id, ref: ref }) }));
    });
    return FormFieldWrapper;
};
