import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { componentSpacing } from '../../../css-utils.css.js';
import { Icon } from '../../icon/icon.js';
import { TextBody } from '../../text/index.js';
import { Flex } from '../flex/flex.js';
import { skeletonStyles } from './skeleton.css.js';
export const Skeleton = ({ animated = true, fixedSize = false, round = false, size = 'small', style, className = '', message, }) => {
    return (_jsx("div", { className: `${skeletonStyles({
            animated,
            fixedSize,
            round,
            size: style?.height ? undefined : size, // if height is set, use the height for the size
        })} ${className}`, style: style, children: message && (_jsxs(_Fragment, { children: [_jsx(Icon, { symbol: "loading", spinning: true, size: "small", className: componentSpacing({ marginRight: 'small' }) }), _jsx(TextBody, { color: "subtle", weight: "medium", children: message })] })) }));
};
export const SkeletonGroup = ({ className, count = 1, direction = 'column', gap = 'small', randomWidths = false, skeletons = {}, }) => {
    const { style, ...otherProps } = skeletons;
    return (_jsx(Flex, { className: className, align: "start", direction: direction, gap: gap, children: [...Array(count)].map((_, i) => (_jsx(Skeleton, { style: {
                width: randomWidths
                    ? `${Math.floor(Math.random() * 50) + 50}%`
                    : null,
                ...style,
            }, ...otherProps }, `skeleton-${i.toString()}`))) }));
};
