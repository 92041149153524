import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Suspense, useEffect } from 'react';
import { useFloating, autoUpdate, offset, flip, shift, useDismiss, useRole, useClick, useInteractions, FloatingFocusManager, FloatingOverlay, FloatingPortal, } from '@floating-ui/react';
import { Scrollbar } from '../scrollbar/scrollbar.js';
import { popoverContentStyles, popoverFooterStyles, popoverHeaderStyles, popoverOverlayStyles, popoverStyles, } from './popover.css.js';
export const Popover = ({ anchorEl, children, fallbackPlacements = [], footer, header, disableFocusTrap = false, id, onClose, onMouseLeave, open, placement = 'bottom', preventAutoFocus = false, size, testId, type, }) => {
    if (!onClose && !onMouseLeave) {
        throw new Error('Popover must have either an onClose or onMouseLeave prop');
    }
    const onOpenChange = onClose || onMouseLeave;
    const { refs, floatingStyles, context } = useFloating({
        open,
        onOpenChange,
        placement,
        middleware: [
            flip({
                crossAxis: true,
                fallbackPlacements,
            }),
            offset(type !== 'submenu' ? 4 : 0),
            shift({ padding: 8, crossAxis: true }),
        ],
        whileElementsMounted: autoUpdate,
    });
    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);
    const { getFloatingProps } = useInteractions([click, dismiss, role]);
    useEffect(() => {
        if (typeof anchorEl === 'function') {
            refs.setReference(anchorEl());
        }
        else {
            refs.setReference(anchorEl);
        }
    }, [refs, anchorEl]);
    if (!open)
        return null;
    return (_jsx(FloatingPortal, { children: _jsx(FloatingFocusManager, { context: context, disabled: disableFocusTrap, visuallyHiddenDismiss: true, returnFocus: type !== 'submenu', order: preventAutoFocus ? ['reference', 'content'] : ['content'], children: _jsxs(_Fragment, { children: [_jsxs("div", { id: id, "data-testid": testId, className: popoverStyles({ size, type }), ref: refs.setFloating, style: floatingStyles, ...getFloatingProps(), children: [header && (_jsx(Suspense, { children: _jsx("header", { className: popoverHeaderStyles, children: header }) })), _jsx(Scrollbar, { children: _jsx("div", { className: popoverContentStyles, children: children }) }), footer && (_jsx(Suspense, { children: _jsx("footer", { className: popoverFooterStyles, children: footer }) }))] }), type !== 'submenu' && (_jsx(FloatingOverlay, { className: popoverOverlayStyles, lockScroll: true }))] }) }) }));
};
