'use client';

import { makeUuid } from '@knapsack/utils';
import { exchangeFigmaOAuthCodeForToken } from '@/services/figma-oauth-client';
import { openPopup } from '@/utils/popup';

const FIGMA_CLIENT_ID = process.env.NEXT_PUBLIC_FIGMA_CLIENT_ID;
if (!FIGMA_CLIENT_ID) {
  throw new Error('NEXT_PUBLIC_FIGMA_CLIENT_ID env var is not set');
}

export type OAuthService = 'figma';

export type OAuthWindowMessage =
  | {
      type: 'auth.complete';
      service: OAuthService;
      code: string;
      state: string;
    }
  | {
      type: 'auth.error';
      service: OAuthService;
      error: string;
    };

function getAuthCodeLink({ service }: { service: OAuthService }): {
  /** Send users here to login */
  link: string;
  /** Verify this equals query `state` when they get back */
  state: string;
  /** Users will be redirected here after logging in. Often needed in next step */
  redirectUrl: string;
  scopes: string[];
} {
  const redirectUrl = new URL(
    `/auth/${service}`,
    window.location.origin,
  ).toString();
  switch (service) {
    /**
     * https://www.figma.com/developers/api#oauth2
     */
    case 'figma': {
      const baseUrl = 'https://www.figma.com/oauth';
      const scopes: Array<
        | 'files:read'
        // requires "enterprise" plan for file_variables
        | `file_variables:${'read' | 'write'}`
        | 'file_comments:write'
        | `file_dev_resources:${'read' | 'write'}`
        | 'webhooks:write'
      > = [
        'files:read',
        'webhooks:write',
        'file_comments:write',
        'file_dev_resources:read',
        'file_dev_resources:write',
      ];
      const state = makeUuid();
      const responseType = 'code';
      const url = new URL(baseUrl);
      url.searchParams.set('client_id', FIGMA_CLIENT_ID);
      url.searchParams.set('redirect_uri', redirectUrl);
      url.searchParams.set('scope', scopes.join(','));
      url.searchParams.set('state', state);
      url.searchParams.set('response_type', responseType);
      return {
        link: url.toString(),
        state,
        redirectUrl,
        scopes,
      };
    }
    default: {
      const _exhaustiveCheck: never = service;
      throw new Error(`Unsupported OAuth Service: ${service}`);
    }
  }
}

/**
 * This will open a popup window to the OAuth login page for the given service.
 * When the user is done logging in, this function's promise will resolve with `code` query param because of {@link handleRedirectLoad}
 * The step after this function is to take the returned `code` and send it to the server to get an access token.
 */
function getOAuthCode({
  service,
  popupWidth = 650,
  popupHeight = 1500,
}: {
  service: OAuthService;
  popupWidth?: number;
  popupHeight?: number;
}): Promise<{ code: string; redirectUrl: string; scopes: string[] }> {
  return new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      reject(new Error(`Timed out waiting for ${service} auth`));
    }, 2 * 60_000);
    const { link, state, redirectUrl, scopes } = getAuthCodeLink({
      service,
    });

    const miniWindow = openPopup({
      url: link,
      name: `knapsack:auth:${service}`,
      width: popupWidth,
      height: popupHeight,
    });

    const handleMsg = (event: MessageEvent<OAuthWindowMessage>) => {
      if (event.origin !== window.location.origin) return;
      switch (event.data.type) {
        case 'auth.complete': {
          clearTimeout(id);
          if (state !== event.data.state) {
            reject(
              new Error(`Invalid state received from Figma auth callback.`),
            );
            return;
          }
          window.removeEventListener('message', handleMsg);
          const { code } = event.data;

          resolve({ code, redirectUrl, scopes });
          return;
        }
        case 'auth.error': {
          clearTimeout(id);
          window.removeEventListener('message', handleMsg);
          reject(new Error(event.data.error));
          return;
        }
        default: {
          const _exhaustiveCheck: never = event.data;
        }
      }
    };

    const handleFocusEvent = () => {
      clearTimeout(id);
      window.removeEventListener('message', handleMsg);
      miniWindow.close();
      reject(new Error('User closed popup without authenticating'));
    };

    window.addEventListener('focus', handleFocusEvent, { once: true });
    window.addEventListener('message', handleMsg);
  });
}

function sendOAuthWindowMessage(message: OAuthWindowMessage) {
  const targetWindow: Window = window.opener;
  if (!targetWindow) {
    throw new Error(
      `This page should only be opened as a popup by another page`,
    );
  }
  targetWindow.postMessage(message, window.location.origin);
}

/**
 * This should be called when `/auth/${service}` is loaded in the popup window.
 * It will send a message to the parent window with the `code` query param & then close. This will cause {@link getOAuthCode} to resolve.
 */
export function handleRedirectLoad({ service }: { service: OAuthService }) {
  const targetWindow: WindowProxy = window.opener;
  if (!targetWindow) {
    throw new Error(
      `This page should only be opened as a popup by another page`,
    );
  }
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');

  if (!code) {
    const error = `No code returned from ${service}`;
    sendOAuthWindowMessage({
      type: 'auth.error',
      service,
      error,
    });
    window.close();
    return;
  }
  const state = params.get('state');
  if (!state) {
    const error = `No state returned from ${service}`;
    sendOAuthWindowMessage({
      type: 'auth.error',
      service,
      error,
    });
    window.close();
    return;
  }

  sendOAuthWindowMessage({
    type: 'auth.complete',
    service,
    code,
    state,
  });

  window.close();
}

export async function oAuthLogin({ service }: { service: OAuthService }) {
  switch (service) {
    case 'figma': {
      const { code, redirectUrl, scopes } = await getOAuthCode({
        service,
      });
      await exchangeFigmaOAuthCodeForToken({
        code,
        redirectUrl,
        scopes,
      });
      break;
    }
    default: {
      const _exhaustiveCheck: never = service;
      throw new Error(`Service "${service}" is not an OAuth service`);
    }
  }
}
