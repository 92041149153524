import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { componentSpacing } from '../../../css-utils.css.js';
export const Container = ({ backgroundColor, children, className, margin, marginBlock, marginBottom, marginInline, marginLeft, marginRight, marginTop, maxWidth, padding, paddingBlock, paddingBottom, paddingInline, paddingLeft, paddingRight, paddingTop, position, testId, width, }) => {
    return (_jsx("div", { className: clsx(className, componentSpacing({
            margin,
            marginBlock,
            marginBottom,
            marginInline,
            marginLeft,
            marginRight,
            marginTop,
            padding,
            paddingBlock,
            paddingBottom,
            paddingInline,
            paddingLeft,
            paddingRight,
            paddingTop,
        })), "data-testid": testId, style: { backgroundColor, position, width, maxWidth }, children: children }));
};
